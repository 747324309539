import { useLocation } from "react-router-dom";
function usePathName() {
    const sectionOption = ["#top_menu", "#top_barra_sup"];
    const location = useLocation();
    const path = location.pathname;
    let sectionChose;

    switch (path) {
        case "/home":
            sectionChose = sectionOption[1];
            break;
        default:
            sectionChose = sectionOption[0];
            break;
    }
    return sectionChose;
};

export default usePathName;
