import React from "react";

import { Container, Row, Col } from "react-bootstrap";

//TODO: borrar, siempre no me gusto

export default function AboutUs() {
  return (
    <Container>
      <Row>
        <Col className="col-lg-12  mx-5">
          <h1 className="text-primary my-5 mx-5">
            CLINICA DENTAL HEALT SMILE{" "}
          </h1>

          <p className="text-primary font-1rem my-1 mx-1">
            En Medica-Dental, somos una clínica dental integral. Nos
            especializamos en cualquier tratamiento, desde una extracción
            simple, hasta un tratamiento complicado y estético. Ahora estamos en
            el mejor lugar de Tepic para brindar la mejor atención a lo mejores
            precios.
          </p>
        </Col>

      </Row>
    </Container>
  );
}
