import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./servicios.scss";

function PackageCard({ packageName, price, items, color, number }) {
  return (
    <Col
      style={{ height: "100%" }}
      className={`package-card bg-${color} px-0 d-flex flex-column text-center grow`}
    >
      <div className="text-center" style={{ height: "100%" }}>
        <div
          className={`text-${color === "light" ? "primary" : "light"
            } mt-3 bold font-2rem`}
        >
          {packageName}{" "}
          <div
            className={`text-${color === "light" ? "primary" : "light"
              } font-20 bold d-block mt-2`}
          >
            {number}
          </div>
        </div>
        <div
          className={`lead d-flex flex-column justify-content-start mx-3 
          ${color === "light" ? "text-primary" : "text-light"
            } `}
          style={{ height: "60%", fontSize: "18px" }}
        >
          {items.map((item, index) => (
            <p key={index} >
              {item}
            </p>
          ))}
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-end"
        style={{ height: "10%" }}>
        <p
          className={`text-${color === "light" ? "primary" : "light"
            } font-2rem bold mt-5`}
        >
          ${price}
        </p>
      </div>
    </Col>
  );
}

export default function Section1() {
  const packageData = [
    {
      packageName: "Paquete",
      price: 600,
      items: ["Limpieza", "Cónsulta", "Radiografía(en caso de requerida)", "Revisión con cámara intraoral"],
      color: "light",
      number: "1",
    },
    {
      packageName: "Paquete",
      price: 1200,
      items: ["Resina", "Consulta", "Radiografía(en caso de ser necesaria)", "Revisión c/ cámara intraoral"],
      color: "primary",
      number: "2",
    },
    {
      packageName: "Paquete",
      price: 3500,
      items: ["Consulta", "Limpieza", "Blanqueamiento"],
      color: "light",
      number: "3",
    },
    {
      packageName: "Paquete",
      price: "25,000",
      items: ["Valoración y análisis de implante", "Análisis ConeBeam", "Implante dental", "Rehabilitación sobre implante", "Provisional sobre implante ( de ser necesario)"],
      color: "primary",
      number: "4",
    },
  ];

  return (
    <Container
      name="section1-servicios"
      className="  gradient-primary-dark mx-auto text-center mx-5 my-5 py-5"

    >

      <Row>
        <Col
          className="d-flex justify-content-center align-items-center mx-0"
          xs={12}
          md={12}
          lg={12}
        >
          <h3 className="text-light bold mb-5 font-3rem text-center ">
            Promociones
          </h3>
        </Col>
      </Row>
      {/* <Row>
        <Col
          className="d-flex mx-auto text-center mx-0 "
          sm={12}
          md={12}
          lg={12}
        ></Col>
      </Row> */}

      <div className="d-flex flex-wrap "
        style={{ height: "100%" }}>
        {packageData.map((pack, index) => (
          <Col
            key={index}
            xs={12}
            sm={12}
            md={6}
            lg={3}
            className="d-flex flex-row justify-content-start"
          >
            <PackageCard
              packageName={pack.packageName}
              price={pack.price}
              items={pack.items}
              color={pack.color}
              number={pack.number}
            />
          </Col>
        ))}
      </div>

    </Container>
  );
}
