import React from "react";
import Footer from "./Footer";

import usePathName from "../hooks/usePathName";

function Layout({ children }) {
  const sectionChose = usePathName();

  return (
    <div>
      {children}
      <Footer></Footer>

      <a
        href={sectionChose}
        style={{ float: "right" }}
        className="btn btn-lg btn-primary text-white  btn-lg-square rounded back-to-top "
      >
        <i className="bi bi-arrow-up "></i>
      </a>
    </div>
  );
}

export default Layout;
