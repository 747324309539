import React from "react";
import { Col, Container, Row } from "react-bootstrap";
//import mapa from "../../assets/img/mapa1.png";

export default function Ubicanos() {
  return (
    <Container className="" lg={12}  >
      <Row >
        <Col className="d-flex justify-content-center mt-5" lg={12}
        >
          <h3 className="text-primary bold mb-5 font-3rem">Nos ubicamos aqui</h3>
        </Col>

        <Col className="d-flex justify-content-around align-items-end mt-5" lg={12}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3712.520575425433!2d-104.89239182569675!3d21.487320171872796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8427372c0acf55cb%3A0x14af4d4182044d2a!2sHealth%20%26%20Smile%20Dental%20Center!5e0!3m2!1sen!2smx!4v1708651872715!5m2!1sen!2smx"
            className="position-relative rounded w-100 h-100"
            style={{ minHeight: "350px", border: "0" }}
            allowfullscreen=""
            aria-hidden="false"
            title="maps-tepic"
          ></iframe>
        </Col>
      </Row>
    </Container>

  );
}
