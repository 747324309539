import React from "react";


// import Nosotros2 from "./Nosotros2";
import Nosotros from "./Nosotros";
import Banner from "../../components/Banner";


export default function AboutUsPage() {
  return (
    <div>
      <Banner name="Quienes somos"></Banner>
      <Nosotros></Nosotros>
      {/* ==Contiene la informacion de los doctores , revisalo antes de borrarlo== */}
      {/* <Nosotros2/> */}

    </div>
  );
}
