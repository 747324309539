import React from "react";
import Servicios from "./Servicios";
import Banner from "../../components/Banner";


export default function ServicePage() {
  return (
    <div>
      <Banner name="Nuestros servicios"></Banner>
      <Servicios></Servicios>
    </div>
  );
}
