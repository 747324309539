import React from "react";
import { Container, Row } from "react-bootstrap";
import { FaWhatsapp } from "react-icons/fa";
import "./servicios.scss";

export default function Section2() {
  return (
    <Container name="section2-servicios" className=" bg-light">
      <Row className="div-diente gb-primary-img-servicios">
        <div className="d-flex justify-content-center flex-row">
          <div className="p-5">
            <h1 className="text-secondary font-3rem bold">
              Haz una cita hoy mismo
            </h1>
            <button className=" m-5 pt-1" style={{ borderColor: "#F1F3F9", backgroundColor: "#2E9B84", borderRadius: "10px", width: "80%" }}>
              <a
                className=" bold font-2rem mt-5" style={{ textDecoration: "none" }}
                href="https://wa.me/5213112697923" target="_blank" rel="noreferrer"
              >

                <div className="text-light">  <FaWhatsapp /></div>
                <div className="text-light">haz tu cita hoy</div>
              </a>
            </button>
          </div>
          <div>{/* <img src={logo}  />  */}</div>
        </div>
      </Row>

    </Container >
  );
}
